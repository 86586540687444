import { render } from "./StoryItem.vue?vue&type=template&id=7b610c90"
import script from "./StoryItem.ts?vue&type=script&lang=ts"
export * from "./StoryItem.ts?vue&type=script&lang=ts"

import "./story-item.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7b610c90"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7b610c90', script)) {
    api.reload('7b610c90', script)
  }
  
  module.hot.accept("./StoryItem.vue?vue&type=template&id=7b610c90", () => {
    api.rerender('7b610c90', render)
  })

}

script.__file = "src/views/Catalog/components/StoryItem/StoryItem.vue"

export default script