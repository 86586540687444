import { render } from "./ProfileStory.vue?vue&type=template&id=6a69fae6"
import script from "./ProfileStory.ts?vue&type=script&lang=ts"
export * from "./ProfileStory.ts?vue&type=script&lang=ts"

import "./profile-story.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6a69fae6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6a69fae6', script)) {
    api.reload('6a69fae6', script)
  }
  
  module.hot.accept("./ProfileStory.vue?vue&type=template&id=6a69fae6", () => {
    api.rerender('6a69fae6', render)
  })

}

script.__file = "src/views/Catalog/components/ProfileStory/ProfileStory.vue"

export default script