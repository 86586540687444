import { render } from "./BasePopup.vue?vue&type=template&id=2c4af68e"
import script from "./BasePopup.ts?vue&type=script&lang=ts"
export * from "./BasePopup.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2c4af68e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2c4af68e', script)) {
    api.reload('2c4af68e', script)
  }
  
  module.hot.accept("./BasePopup.vue?vue&type=template&id=2c4af68e", () => {
    api.rerender('2c4af68e', render)
  })

}

script.__file = "src/components/PaidMessagesPopup/components/BasePopup/BasePopup.vue"

export default script