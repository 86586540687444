import { render } from "./MalePopup.vue?vue&type=template&id=c84cec98"
import script from "./MalePopup.ts?vue&type=script&lang=ts"
export * from "./MalePopup.ts?vue&type=script&lang=ts"

import "./malePopup.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c84cec98"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c84cec98', script)) {
    api.reload('c84cec98', script)
  }
  
  module.hot.accept("./MalePopup.vue?vue&type=template&id=c84cec98", () => {
    api.rerender('c84cec98', render)
  })

}

script.__file = "src/components/PaidMessagesPopup/MalePopup/MalePopup.vue"

export default script