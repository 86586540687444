import { render } from "./CreateStory.vue?vue&type=template&id=d60424ca"
import script from "./CreateStory.ts?vue&type=script&lang=ts"
export * from "./CreateStory.ts?vue&type=script&lang=ts"

import "./create-story.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "d60424ca"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d60424ca', script)) {
    api.reload('d60424ca', script)
  }
  
  module.hot.accept("./CreateStory.vue?vue&type=template&id=d60424ca", () => {
    api.rerender('d60424ca', render)
  })

}

script.__file = "src/views/Rooms/components/CreateStory/CreateStory.vue"

export default script