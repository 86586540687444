import { render } from "./AboutStory.vue?vue&type=template&id=09ee79ac"
import script from "./AboutStory.ts?vue&type=script&lang=ts"
export * from "./AboutStory.ts?vue&type=script&lang=ts"

import "./aboutStory.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "09ee79ac"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('09ee79ac', script)) {
    api.reload('09ee79ac', script)
  }
  
  module.hot.accept("./AboutStory.vue?vue&type=template&id=09ee79ac", () => {
    api.rerender('09ee79ac', render)
  })

}

script.__file = "src/views/Stories/components/AboutStory/AboutStory.vue"

export default script