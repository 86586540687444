import { render } from "./Story.vue?vue&type=template&id=9bb256d0"
import script from "./Story.ts?vue&type=script&lang=ts"
export * from "./Story.ts?vue&type=script&lang=ts"

import "./story.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "9bb256d0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('9bb256d0', script)) {
    api.reload('9bb256d0', script)
  }
  
  module.hot.accept("./Story.vue?vue&type=template&id=9bb256d0", () => {
    api.rerender('9bb256d0', render)
  })

}

script.__file = "src/views/Stories/components/Story/Story.vue"

export default script