import { useStore } from 'vuex';
import { StoreInjectionKey } from '../../constants';
import { CHATS_STORE_KEY } from '../constants';
import { ChatsGetter } from '../enums';

export const useChatGetter = <R = unknown>(key: ChatsGetter): R => {
  const store = useStore<typeof StoreInjectionKey>();

  return store.getters[`${CHATS_STORE_KEY}/${key}`];
};
